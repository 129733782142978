import React from "react"
import styles from "../../scss/components/TheBirraBehind/_Map.module.scss"
import { Link } from "gatsby"
import LogoImageLolla from "./LogoImageLolla"
import LogoImageMute from "./LogoImageMute"

function Venues({ isMobile, venue }) {
  // console.log("data each venue on Venues via props:", JSON.stringify(venue))

  const { location, iconUrl, top, left, topMobile, leftMobile } = venue
  const isLolla = location.includes("lolla")

  // const leftLolla = "25px"
  // const topLolla = "-130px"
  // const topMute = "-22.5px"
  // const leftMute = "45.5px"
  // const leftMobileLolla = "0px"
  // const topMobileLolla = "-87.5px"
  // const leftMobileMute = "27.5px"
  // const topMobileMute = "7.5px"

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          top: isMobile ? topMobile : top,
          left: isMobile ? leftMobile : left,
          // top: isMobile ? isLolla ? topMobileLolla : topMobileMute : isLolla ? topLolla : topMute,
          // left: isMobile ? isLolla ? leftMobileLolla : leftMobileMute : isLolla ? leftLolla : leftMute,
        }}
      >
        <Link
          to={`/lamusicaentusmanos/${location}`}
          id={`/lamusicaentusmanos/${location}`}
          className={styles.HeaderLink}
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "menu",
              event_action: "Click",
              event_label: `https://www.budweiser.com.ar/lamusicaentusmanos/${location}`,
              interaction: true,
              component_name: "",
              element_text: "",
            })
          }}
        >
          {/* {isLolla ? (
            <LogoImageLolla isLolla={isLolla} />
          ) : (
            <LogoImageLolla isLolla={isLolla} />
          )} */}
          <LogoImageLolla isLolla={isLolla} />
          <div
            className={styles.locationPoint}
            style={{
              margin: "0 auto",
            }}
          />
        </Link>
      </div>
    </div>
  )
}

export default Venues

import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import SEO from "../../seo"
import arrowDown from "../../../images/icons/arrowDown.png"
import Footer from "../../Footer"
import Main from "../Main"
import styles from "../../../scss/pages/_thebirrabehind.module.scss"
import MapSection from "../MapSection"

export default function Backtoliveuy() {
  return <div styles={{ overflow: "hidden", overflowX: "hidden" }}>
          <SEO title="BackToLive" />
          <Container fluid className="m-0 p-0">
            <section>
              <Main />
            </section>
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#MapSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src={arrowDown} className={styles.ArrowDownSection} />
                </a>
              </Col>
            </Row>
            <section id="MapSection">
              <MapSection  uruguay={true}/>
            </section>
            {/* <div style={{ paddingTop: "2%" }}> */}
            <Footer />
            {/* </div> */}
          </Container>
        </div>
}

import React, { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import localidades from "./proviciasylocalidades.json"
import provincias from "./provincias.json"
import styles from "./../../../scss/components/TheBirraBehind/_StepForm.module.scss"
import * as Yup from "yup"
import { formatDOB } from "../../../utils/functions"
import Cookies from "js-cookie"
import { useHistory } from "react-router"
import selectArrow from "./../../../images/TheBirraBehind/arrow-down.svg"
import { navigate } from "gatsby"
const SignupSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Demasiado Corto!")
    .max(50, "Muy largo!")
    .required("Este campo es obligatorio"),
  email: Yup.string()
    .email("Mail invalido")
    .required("Este campo es obligatorio"),
  province: Yup.string().required("Este campo es obligatorio"),
  birthDate: Yup.string().required("Este campo es obligatorio"),

  instagram: Yup.string()
    .min(2, "Demasiado Corto!")
    .max(50, "Muy largo!")
    .required("Este campo es obligatorio"),
})

export default function Step1({
  setPage,
  setValues,
  propValues,
  isSafari,
  isMobile,
  setStep,
  selectedEvent,
  selectedProvince,
  location
}) {
  let showBtn
  const [cities, setCities] = useState([])
  const [error, setError] = useState("")
  const [dateFieldClicked, setDateFieldClicked] = useState(false)
  const [birthDateError, setBirthDateError] = useState("")
  const [errorDate, setErrorDate] = useState("")
  const [preferenceError, setPreferenceError] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  let history = useHistory()


  const CheckboxInput = ({ name, label, isChecked, setIsChecked, isMobile }) => {
    const toggleCheck = e => {
      setIsChecked(() => !isChecked)
    }
    return (
        <div>
          <input
              type="checkbox"
              className={styles.termsRadio}
              name={name}
              id={name}
              checked={isChecked}
              onChange={toggleCheck}
          />
          <label htmlFor={name}>{/* <span>{label}</span> */}</label>
        </div>
    )
  }

  return (
    <Formik
      initialValues={{
        fullname: "",
        email: "",
        province: "",
        birthDate: "",
        instagram: "",
        preference: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        // same shape as initial values
        console.log(values)
        let newValues = { ...propValues, ...values }

        const date = new Date()
        const year = date.getFullYear()

        if (newValues.birthDate.length < 10)
          return setErrorDate("Fecha inválida.")
        if (Number(newValues.birthDate.split("/")[0]) > 31)
          return setErrorDate("Fecha inválida.")
        if (Number(newValues.birthDate.split("/")[1]) > 12)
          return setErrorDate("Fecha inválida.")
        if (Number(newValues.birthDate.split("/")[2]) >= year)
          return setErrorDate("Fecha inválida.")
        setErrorDate("")
        setValues(newValues)

        console.log("submit",selectedEvent)
        newValues = {
          ...propValues,
          ...values,
          event: selectedEvent.name,
          eventId: selectedEvent.id,
          pdv: selectedEvent.address,
        }
        console.log("newValues", newValues)

        const td = Cookies.get("_td")

        if(!newValues.preference){
            setPreferenceError("Este campo es obligatorio");
            console.log("error preference")
            return
        }

        const url = location.href.split("?")[0]

        let data = {
          name: newValues.fullname,
          email: newValues.email,
          state: newValues.province,
          dateofbirth: newValues.birthDate,
          instagram: newValues.instagram,
          genre: "",
          preference: newValues.preference,
          event: newValues.event,
          eventId: newValues.eventId,
          pdv: newValues.pdv,
          url
        }

        console.log("dataaa", data)

        isChecked
            ? fetch(
                "https://wabudweiser-prd.azurewebsites.net/thebirrabehindmusic",
                {
                  // ? fetch("http://localhost:8080/thebirrabehindmusic", {
                  method: "POST",
                  body: JSON.stringify(data),
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
            ).then(response => {
              console.log('debug_response',response)
              if (response.ok) {
                navigate("/lamusicaentusmanos/success")
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Form",
                  event_action: "Success",
                  event_label: selectedProvince.NAME ? selectedProvince.NAME : "",
                  interaction: true,
                  component_name: newValues.event,
                  userUid: `${td}`,
                })
              } else {
                response.text().then(text => {
                  console.error("Error guardando los datos. \n", text)
                })
              }
            }).catch(error=>console.log('debug_error',error))
            : alert("Debes aceptar los terminos y condiciones para continuar") &&
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Form",
              event_action: "Error",
              event_label: "Falta aceptar terminos y condiciones",
              interaction: true,
              component_name: "boton_enviar",
            })

      }}
    >
      {({ errors, touched, values, setFieldValue }) => {
        const localidadesArr = Object.entries(localidades)

        // const validErrors =
        //   errors.fullname ||
        //   errors.email ||
        //   errors.province ||
        //   errors.birthDate ||
        //   errors.instagram

        // if (validErrors) return setError("Debe completar todos los campos.")

        console.log("ERRORS", errors)
        console.log("VALUES", values)

        if (values.province) {
          const filter = localidadesArr.filter(([key, value]) => {
            return key === values.province
          })
          const justStrings = Object.fromEntries(filter)
          setCities(justStrings[values.province])
        }

        return (
          <Form className={styles.formContainer}>
            <div className={styles.fieldContainer}>
              <Field
                style={{
                  border:
                    errors.fullname && touched.fullname
                      ? "3px solid #dd1a32"
                      : "",
                }}
                className={styles.inputStyle}
                name="fullname"
                placeholder="Nombre y apellido"
                onBlur={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Form Bud Music",
                    event_action: "Paso 1",
                    event_label: `Nombre y Apellido`,
                    interaction: true,
                    component_name: "boton_siguiente",
                  })
                  setStep("1")
                }}
              />
              {/* {errors.fullname && touched.fullname ? (
                <div style={{ color: "#dd1a32" }}>{errors.fullname}</div>
              ) : null} */}
              <Field
                style={{
                  border:
                    errors.email && touched.email ? "3px solid #dd1a32" : "",
                }}
                className={styles.inputStyle}
                name="email"
                type="email"
                placeholder="Mail"
                onBlur={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Form Bud Music",
                    event_action: "Paso 2",
                    event_label: `Email`,
                    interaction: true,
                    component_name: "boton_siguiente",
                  })
                  setStep("2")
                }}
              />
              {/* {errors.email && touched.email ? (
                <div style={{ color: "#dd1a32" }}>{errors.email}</div>
              ) : null} */}

              <Field
                style={{
                  border:
                    errors.province && touched.province
                      ? "3px solid #dd1a32"
                      : "",
                }}
                className={styles.inputStyle}
                name="province"
                placeholder="Provincia"
                component="select"
                id="province"
                onBlur={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Form Bud Music",
                    event_action: "Paso 3",
                    event_label: `Provincia`,
                    interaction: true,
                    component_name: "boton_siguiente",
                  })
                  setStep("3")
                }}
              >
                <option selected hidden>
                  Seleccione provincia
                </option>
                {provincias.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </Field>
              {/* {errors.province && touched.province ? (
                <div style={{ color: "#dd1a32" }}>{errors.province}</div>
              ) : null} */}

              <Field
                style={{
                  border:
                    (errors.birthDate || birthDateError) && touched.birthDate
                      ? "3px solid #dd1a32"
                      : "",
                }}
                className={styles.inputStyle}
                type="text"
                name="birthDate"
                onChange={e => {
                  setBirthDateError("")
                  const onlyNums = e.target.value.replace(/[^0-9]/g, "")
                  const formatedBirthDate = formatDOB(onlyNums)
                  setFieldValue("birthDate", formatedBirthDate)
                }}
                placeholder={
                  dateFieldClicked ? "DD/MM/AAAA" : "Fecha de nacimiento"
                }
                onClick={() => setDateFieldClicked(true)}
                onBlur={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Form Bud Music",
                    event_action: "Paso 4",
                    event_label: `Fecha de nacimiento`,
                    interaction: true,
                    component_name: "boton_siguiente",
                  })
                  setStep("4")
                }}
              />
              {/* {(errors.birthDate || birthDateError) && touched.birthDate ? (
                <div style={{ color: "#dd1a32" }}>
                  {errors.birthDate || birthDateError}
                </div>
              ) : null} */}

              <Field
                style={{
                  border:
                    errors.instagram && touched.instagram
                      ? "3px solid #dd1a32"
                      : "",
                }}
                className={styles.inputStyle}
                name="instagram"
                placeholder="Instagram"
                onBlur={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Form Bud Music",
                    event_action: "Paso54",
                    event_label: `Instagram`,
                    interaction: true,
                    component_name: "boton_siguiente",
                  })
                  setStep("5")
                }}
              />
              <div className={styles.fieldContainer4}>
                <h1 className={styles.titleText4}>
                    ¿EN QUÉ OCASIONES ACOSTUMBRÁS A CONSUMIR CERVEZA?
                </h1>
                  <img src={selectArrow} className={styles.selectArrow} />
                <Field
                    className={styles.inputStyle2}
                    name="preference"
                    component="select"
                    onBlur={() => {
                      window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Form Bud Music",
                        event_action: "Paso 6",
                        event_label: `Pregunta ocasión de consumo`,
                        interaction: true,
                        component_name: "boton_siguiente",
                      })
                      setStep("6")
                    }}
                >

                  <option hidden>
                    Seleccione una ocasión de consumo
                  </option>
                  <option value="Cocinando / Almorzando o cenando solo o acompañado">Cocinando / Almorzando o cenando solo o acompañado</option>
                  <option value="Con amigos / compañeros socializando en casa o en un bar">Con amigos / compañeros socializando en casa o en un bar</option>
                  <option value="Cuando me quiero relajar, viendo TV, en la plaza, usando RRSS o tomando sol">Cuando me quiero relajar, viendo TV, en la plaza, usando RRSS o tomando sol</option>
                  <option value="En una fiesta, preboliche, etc">En una fiesta, preboliche, etc</option>
                  <option value="No tomo cerveza">No tomo cerveza</option>
                  <option value="Traslándome a algún lugar, mientras trabajo o hago mandados">Traslándome a algún lugar, mientras trabajo o hago mandados</option>
                </Field>
                {preferenceError && !values.preference ? (
                    <div style={{ color: "#dd1a32", textAlign: "left" }}>
                      {preferenceError}
                    </div>
                ) : null}
                {/* {errors.favouriteBand && touched.favouriteBand ? (
              <div style={{ color: "#dd1a32" }}>{errors.favouriteBand}</div>
            ) : null} */}
              </div>
              {/* {errors.instagram && touched.instagram ? (
                <div style={{ color: "#dd1a32" }}>{errors.instagram}</div>
              ) : null} */}
              {errorDate && (
                <div
                  style={{
                    color: "#dd1a32",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}
                >
                  {errorDate}
                </div>
              )}
            </div>
            <div
                className={
                   styles.btnContainerSend
                }
            >
              <div className={styles.termsContainer}>
                <CheckboxInput
                    name="remember-me"
                    label="Remember Me"
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                />
                <h4 className={styles.termsText}>
                  He leído y acepto&nbsp;
                  <a
                      className={styles.link}
                      target="_blank"
                      href="/Bases y Condiciones  Promoción la Música en tus manos.pdf"
                  >
                    Bases y Condiciones
                  </a>
                  ,&nbsp;
                  <a
                      className={styles.link}
                      target="_blank"
                      href="/PoliticadePrivacidad.pdf"
                  >
                    Políticas de Privacidad
                  </a>
                  ,&nbsp;
                  <a
                      className={styles.link}
                      target="_blank"
                      href="/Terminos-Condiciones.pdf"
                  >
                    Términos y condiciones
                  </a>
                  &nbsp;y activaciones de marketing.
                </h4>
              </div>
              {/* TERMS END */}
              <button className={styles.btnSend} type="submit">
                Enviar
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

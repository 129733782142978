import React, { useEffect, useState } from "react"
import styles from "../../scss/components/TheBirraBehind/_Map.module.scss"
import Logo2 from "../../images/TheBirraBehind/logo.svg"
import whiteLogo from "../../images/TheBirraBehind/white-logo.svg"
import whiteBorderLogo from "../../images/TheBirraBehind/white-border-logo.svg"
import verticalLogo from "../../images/TheBirraBehind/vertical-logo.svg"
import BackIcon from "../../images/BackToLive/backIcon.png"
import Map from "./Map"
import ReactTooltip from "react-tooltip"
import Event from "./Event"
import StepForm from "./StepForm"
import useDeviceDetect from "./hooks/useDevice"
import eventData from "./utils/events.json"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import geoJson from "./utils/provincias.json"

export default function MapSection({ uruguay, provincesEvents, setStep, eventId, venues, location }) {
  const events = provincesEvents
  const [content, setContent] = useState("")
  const [isProvince, setIsProvince] = useState(uruguay ? true : false)
  const [isArtistSelected, setIsArtistSelected] = useState(false)
  const [selectedProvince, setSelectedProvince] = useState(
    uruguay ? { NAME: "Punta del Este", URL: "Uruguay.svg" } : ""
  )
  const [isForm, setIsForm] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState()
  const { isMobile, isSafari } = useDeviceDetect()
  const [isArtistDisplayInMobile, setIsArtistDisplayInMobile] = useState(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if(!mounted){
        setMounted(true)
        return
    }

    if (eventId && events.some((event) => event.node.id === eventId)) {
      const event = events.find((event) => event.node.id === eventId)
      setIsArtistSelected(true)
      const geoProvince = geoJson.objects["ARG_adm1"].geometries.find(
          (province) => province.properties.NAME === event.node.province
      );
      if(!isMobile){
        setSelectedProvince(geoProvince.properties)
        setIsProvince(true)
      } else {
        setIsProvince(false)
        setSelectedProvince("")
      }
      setSelectedEvent(events.find((event) => event.node.id === eventId));


      document
          .querySelector("#MapSection")
          .scrollIntoView({ behavior: "smooth" })
    }
  }, [eventId, mounted])

  useEffect(() => {
    setIsArtistDisplayInMobile(isMobile && !isProvince && isArtistSelected)
  }, [isMobile, isProvince, isArtistSelected])

  function handleBack() {
    setIsArtistSelected(false)
    setIsProvince(false)
  }

  function handleFormBack() {
    setIsForm(false)
    setIsArtistSelected(false)

    if(isMobile){
      handleBack()
    }
  }

  function handleToForm() {
    setIsForm(true)
  }

  const data = useStaticQuery(graphql`
    query background {
      file(base: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "../../images/TheBirraBehind" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return !uruguay ? (
    <div className={styles.container}>
      <Img fluid={data.file.childImageSharp.fluid}
           loading="eager" className={styles.backgroundImg}/>
      {isMobile ? (
        <div className={styles.wrapper}>
          <img
            src={whiteBorderLogo}
            className={!isForm ? styles.logoBudMobile : styles.logoBudMobile2}
          />
          {isArtistDisplayInMobile ? null : (
            <div className={styles.firstColumn}>
              <h1 className={styles.firstText}>
                {isProvince ? "LAS MEJORES NOCHES" : "DESCUBRÍ LOS MEJORES EVENTOS"}
              </h1>
              {!isProvince ? (
                <h2 className={styles.secondText}>NAVEGÁ EL MAPA</h2>
              ) : (
                <>
                  <h2 className={styles.provinceText}>
                    {selectedProvince.NAME}
                  </h2>
                  <h2 className={styles.selectPlaceText}>
                    SELECCIONÁ EL LUGAR
                  </h2>
                </>
              )}
            </div>
          )}
          {isArtistDisplayInMobile ? null : (
            <div className={styles.secondColumn}>
              <Map
                eventos={events}
                setTooltipContent={setContent}
                isProvince={isProvince}
                setIsProvince={setIsProvince}
                selectedProvince={selectedProvince}
                setSelectedProvince={setSelectedProvince}
                setIsArtistSelected={setIsArtistSelected}
                isMobile={isMobile}
                isSafari={isSafari}
                eventData={eventData}
                setSelectedEvent={setSelectedEvent}
                venues={venues}                
              />
              <ReactTooltip>{content}</ReactTooltip>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <img className={styles.verticalLogo} src={verticalLogo} alt={"budweiser"}/>
          <div className={styles.firstColumn}>
            <h1 className={styles.firstText} style={{fontSize: isProvince ? 90 : 70, letterSpacing: isProvince ? 0 : null}}>
              {!isProvince
                ? <>DESCRUBRÍ <br/> LOS MEJORES<br/> EVENTOS</>
                : <>LAS <br/> MEJORES<br/> NOCHES</>}
            </h1>
            {!isProvince ? (
              <h2 className={styles.secondText}>NAVEGÁ EL MAPA</h2>
            ) : (
              <>
                <h2 className={styles.provinceText}>{selectedProvince.NAME}</h2>
                <h2 className={styles.selectPlaceText}>SELECCIONÁ EL LUGAR</h2>
              </>
            )}
          </div>
          {/* <div style={{ width: "80px" }}></div> */}
          <div className={styles.secondColumn}>
            <Map
              eventos={events}
              setTooltipContent={setContent}
              isProvince={isProvince}
              setIsProvince={setIsProvince}
              selectedProvince={selectedProvince}
              setSelectedProvince={setSelectedProvince}
              setIsArtistSelected={setIsArtistSelected}
              isMobile={isMobile}
              isSafari={isSafari}
              eventData={eventData}
              setSelectedEvent={setSelectedEvent}
              venues={venues}
            />
            <ReactTooltip>{content}</ReactTooltip>
          </div>
          <div
            className={
              styles.thirdColumn
            }
          >
            {/*<img*/}
            {/*  src={Logo2}*/}
            {/*  className={!isForm ? styles.logoBud : styles.logoBud2}*/}
            {/*/>*/}
            {<div className={(!isArtistSelected && !isForm) ? styles.logoContainer : styles.logoContainer2}>
              <img className={styles.whiteLogo} src={whiteLogo} alt={"logo"}/>
              <p>LA MÚSICA <span>EN TUS MANOS</span></p>
            </div>}
          </div>
        </div>
      )}
      {isProvince && !isForm ? (
        <div className={!isMobile ? styles.backBtn : styles.backBtnMobile}>
          <a className={styles.btnWrapper} onClick={handleBack}>
            <img src={BackIcon} style={{ objectFit: "contain" }} />
            <h3>Volver</h3>
          </a>
        </div>
      ) : null}

      {isArtistSelected ? (
        <>
          <div className={styles.eventContainer}>
            <Event
              selectedEvent={selectedEvent}
              isForm={isForm}
              handleToForm={handleToForm}
            />
            {isMobile ? (
              <div
                style={{ display: isForm ? "none" : "show" }}
                className={styles.backBtnMobile2}
              >
                <a className={styles.btnWrapper2} onClick={handleBack}>
                  <img src={BackIcon} style={{ objectFit: "contain" }} />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {isForm ? (
        <StepForm
          selectedEvent={selectedEvent}
          selectedProvince={selectedProvince}
          setStep={setStep}
          onBack={handleFormBack}
          location={location}
        />
      ) : null}
    </div>
  ) : (
    //SOLO PARA URUGUAY
    <div className={styles.container}>
      {isMobile ? (
        <div className={styles.wrapper}>
          <img
            src={Logo2}
            className={!isForm ? styles.logoBudMobile : styles.logoBudMobile2}
          />
          {isArtistDisplayInMobile ? null : (
            <div className={styles.firstColumn}>
              <h1 className={styles.firstText}>
                Descubrí los eventos de Uruguay
              </h1>
              {!isProvince ? (
                <h2 className={styles.secondText}>¡Tocando en el mapa!</h2>
              ) : (
                <>
                  <h2 className={styles.provinceText}>
                    {selectedProvince.NAME}
                  </h2>
                  <h2 className={styles.selectPlaceText}>
                    Seleccioná el lugar
                  </h2>
                </>
              )}
            </div>
          )}
          {isArtistDisplayInMobile ? null : (
            <div className={styles.secondColumn}>
              <Map
                eventos={events}
                setTooltipContent={setContent}
                isProvince={isProvince}
                setIsProvince={setIsProvince}
                selectedProvince={selectedProvince}
                setSelectedProvince={setSelectedProvince}
                setIsArtistSelected={setIsArtistSelected}
                isMobile={isMobile}
                isSafari={isSafari}
                eventData={eventData}
                setSelectedEvent={setSelectedEvent}
              />
              <ReactTooltip>{content}</ReactTooltip>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.firstColumn}>
            <h1 className={styles.firstText}>
              {!isProvince
                ? `DESCRUBRÍ LOS MEJORES EVENTOS`
                : "LAS MEJORES NOCHES"}
            </h1>
            {!isProvince ? (
              <h2 className={styles.secondText}>¡NAVEGÁ EL MAPA!</h2>
            ) : (
              <>
                <h2 className={styles.provinceText}>{selectedProvince.NAME}</h2>
                <h2 className={styles.selectPlaceText}>Seleccioná el lugar</h2>
              </>
            )}
          </div>
          {/* <div style={{ width: "80px" }}></div> */}
          <div className={styles.secondColumn}>
            <Map
              eventos={events}
              setTooltipContent={setContent}
              isProvince={isProvince}
              setIsProvince={setIsProvince}
              selectedProvince={selectedProvince}
              setSelectedProvince={setSelectedProvince}
              setIsArtistSelected={setIsArtistSelected}
              isMobile={isMobile}
              isSafari={isSafari}
              eventData={eventData}
              setSelectedEvent={setSelectedEvent}
            />
            <ReactTooltip>{content}</ReactTooltip>
          </div>
          <div
            className={
              isArtistSelected ? styles.thirdColumn2 : styles.thirdColumn
            }
          >
            <img
              src={Logo2}
              className={!isForm ? styles.logoBud : styles.logoBud2}
            />
          </div>
        </div>
      )}
      {isArtistSelected ? (
        <>
          <div className={styles.eventContainer}>
            <Event
              selectedEvent={selectedEvent}
              isForm={isForm}
              handleToForm={handleToForm}
            />
            {isMobile ? (
              <div
                style={{ display: isForm ? "none" : "show" }}
                className={styles.backBtnMobile2}
              >
                <a className={styles.btnWrapper2} onClick={handleBack}>
                  <img src={BackIcon} style={{ objectFit: "contain" }} />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {isForm ? (
        <StepForm
          selectedEvent={selectedEvent}
          selectedProvince={selectedProvince}
          setStep={setStep}
          onBack={handleFormBack}
          location={location}
        />
      ) : null}
    </div>
  )
}

import React, { useState, useEffect } from "react"
import styles from "./../../scss/components/TheBirraBehind/_StepForm.module.scss"
import { Formik, Form, Field } from "formik"
import BackIcon from "./../../images/BackToLive/backIcon.png"

import Step1 from "./Steps/Step1"
import Step2 from "./Steps/Step2"
import Step3 from "./Steps/Step3"
import Step4 from "./Steps/Step4"
import useDeviceDetect from "./hooks/useDevice"

export default function StepForm({ selectedProvince, selectedEvent, setStep, onBack, location }) {
    const [page, setPage] = useState(1)
    const [values, setValues] = useState({})
    const { isMobile, isSafari } = useDeviceDetect()
    let step = <Step1
        setValues={setValues}
        setPage={setPage}
        propValues={values}
        isMobile={isMobile}
        isSafari={isSafari}
        setStep={setStep}
        selectedEvent={selectedEvent}
        selectedProvince={selectedProvince}
        location={location}
    />

    console.log("VALUES", values)
    console.log("ONBACK", onBack)


    return (
        <>
            <div className={styles.stepFormContainer}>
                <div className={styles.formWrap}>
                    <div className={styles.firstColumn}>
                        <div className={styles.backBtn} onClick={onBack}>
                            <a
                                className={styles.btnWrapper}
                            >
                                <img src={BackIcon} style={{ objectFit: "contain" }} />
                                <h3>Volver</h3>
                            </a>
                        </div>
                        {page !== 1 ? (
                            <div className={styles.backBtn}>
                                <a
                                    className={styles.btnWrapper}
                                    onClick={() => setPage(page - 1)}
                                >
                                    <img
                                        src={BackIcon}
                                        style={
                                            isMobile
                                                ? { width: "12px", objectFit: "contain" }
                                                : { objectFit: "contain" }
                                        }
                                    />
                                    <h3>Volver</h3>
                                </a>
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.secondColumn}>{step}</div>
                </div>
            </div>
            {/*  */}
        </>
    )
}

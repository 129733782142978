import React, { useEffect } from "react"
import useDeviceDetect from "./hooks/useDevice"
import styles from "../../scss/components/TheBirraBehind/_Main.module.scss"

// Images & Icons
import logo from "../../images/TheBirraBehind/logobud.svg"
// import ilus from "../../images/TheBirraBehind/ilushome.svg"
import texture from "../../images/TheBirraBehind/textura-fondo.svg"
import redIcon from "../../images/TheBirraBehind/bud-red-icon.png"

export default function Main({eventId}) {
  const { isSafari } = useDeviceDetect()

  useEffect(() => {
      if(!eventId){
          window.scrollTo(0, 0)
      }
  }, [])

  return (
    <div className={isSafari ? styles.containerSafari : styles.container}>
      <img src={texture} className={styles.texture} />
      <div className={styles.textsContainer}>
        <img src={logo} className={styles.logo} />
        {/*<h1 className={styles.secondText}>TE INVITA A DISFRUTAR</h1>*/}
        {/*<h1 className={styles.secondText}>DE LOS MEJORES ARTISTAS</h1>*/}
        {/*<h1 className={styles.secondText}> EN TODO EL PAÍS</h1>*/}
      </div>
      <div className={styles.imageRightContainer}>
        <img src={redIcon} className={styles.redIcon} />
        <h1 className={styles.textDescription}>LA MÚSICA EN TUS MANOS</h1>
      </div>
      <h3 className={styles.callToActionText}>Mirá debajo los próximos eventos</h3>
    </div>
  )
}
